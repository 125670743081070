   // src/App.js
   import React from 'react';
   import StarSwoosh from './components/StarSwoosh.tsx';

   function App() {
     return (
       <div className="App">
         <StarSwoosh />
       </div>
     );
   }

   export default App;