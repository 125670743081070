"use client"

import React, { useState, useEffect, useRef } from 'react'

interface Star {
  x: number
  y: number
  size: number
  vx: number
  vy: number
  rotation: number
  collected: boolean
}

// Simple Button component directly in the file
const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className = '', ...props }) => {
  return (
    <button
      className={`px-4 py-2 rounded-md text-white ${className}`}
      {...props}
    >
      {children}
    </button>
  )
}

// Simple Card component directly in the file
const Card: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="bg-gray-800 p-4 rounded-md shadow-md text-white">{children}</div>
)

const CardHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="mb-4 font-bold text-lg">{children}</div>
)

const CardTitle: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <h2>{children}</h2>
)

const CardContent: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>{children}</div>
)

export default function StarSwoosh() {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [starsSwooshed, setStarsSwooshed] = useState(0)
  const [isStarburstActive, setIsStarburstActive] = useState(false)
  const [starburstTimeLeft, setStarburstTimeLeft] = useState(0)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    let stars: Star[] = []
    let animationFrameId: number
    let pointerX = 0
    let pointerY = 0

    const createStar = (): Star => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      size: Math.random() * 1.5 + 0.5,
      vx: 0,
      vy: 0,
      rotation: Math.random() * Math.PI * 2,
      collected: false,
    })

    const initStars = () => {
      stars = Array.from({ length: 300 }, createStar)
    }

    const updateStars = () => {
      stars.forEach((star) => {
        if (star.collected) {
          const dx = pointerX - star.x
          const dy = pointerY - star.y
          star.x += dx * 0.1
          star.y += dy * 0.1
        } else {
          const dx = pointerX - star.x
          const dy = pointerY - star.y
          const dist = Math.sqrt(dx * dx + dy * dy)

          if (dist < 50) {
            star.collected = true
            setStarsSwooshed((prev) => prev + 1)
          }

          if (dist < 100) {
            const force = (100 - dist) / 100
            const attractionSpeed = isStarburstActive ? 0.6 : 0.2
            star.vx += (dx / dist) * force * attractionSpeed
            star.vy += (dy / dist) * force * attractionSpeed
          }

          star.x += star.vx
          star.y += star.vy
          star.vx *= 0.95
          star.vy *= 0.95
        }

        star.rotation += 0.01

        if (star.x < 0 || star.x > canvas.width || star.y < 0 || star.y > canvas.height) {
          Object.assign(star, createStar())
        }
      })

      // Add new stars more frequently
      if (Math.random() < 0.3 && stars.length < 1000) {
        stars.push(createStar())
      }
    }

    const drawStar = (ctx: CanvasRenderingContext2D, x: number, y: number, size: number, rotation: number) => {
      ctx.save()
      ctx.translate(x, y)
      ctx.rotate(rotation)
      ctx.beginPath()
      for (let i = 0; i < 5; i++) {
        ctx.lineTo(
          Math.cos((i * 4 * Math.PI) / 5) * size,
          Math.sin((i * 4 * Math.PI) / 5) * size
        )
      }
      ctx.closePath()
      ctx.fill()
      ctx.restore()
    }

    const drawStars = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'
      ctx.fillRect(0, 0, canvas.width, canvas.height)

      ctx.fillStyle = 'white'
      stars.forEach((star) => {
        drawStar(ctx, star.x, star.y, star.size, star.rotation)
      })
    }

    const animate = () => {
      updateStars()
      drawStars()
      animationFrameId = requestAnimationFrame(animate)
    }

    const handleResize = () => {
      canvas.width = window.innerWidth / 2  // Only use left half of the screen
      canvas.height = window.innerHeight
      initStars()
    }

    const handlePointerMove = (event: PointerEvent) => {
      const rect = canvas.getBoundingClientRect()
      pointerX = event.clientX - rect.left
      pointerY = event.clientY - rect.top
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    canvas.addEventListener('pointermove', handlePointerMove)
    animate()

    return () => {
      cancelAnimationFrame(animationFrameId)
      window.removeEventListener('resize', handleResize)
      canvas.removeEventListener('pointermove', handlePointerMove)
    }
  }, [isStarburstActive])

  useEffect(() => {
    if (isStarburstActive) {
      const timer = setInterval(() => {
        setStarburstTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer)
            setIsStarburstActive(false)
            return 0
          }
          return prev - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [isStarburstActive])

  const buyStarburst = () => {
    if (starsSwooshed >= 500 && !isStarburstActive) {
      setStarsSwooshed((prev) => prev - 500)
      setIsStarburstActive(true)
      setStarburstTimeLeft(5)
    }
  }

  return (
    <div className="fixed inset-0 bg-black flex">
      <div className="w-1/2 h-full">
        <canvas
          ref={canvasRef}
          className="w-full h-full"
          aria-label="Interactive star field following pointer movement"
        />
      </div>
      <div className="w-1/2 h-full bg-gray-900 p-6 overflow-y-auto">
        <div className="text-white text-center mb-6">
          <h1 className="text-4xl font-bold mb-2">Stars Swooshed</h1>
          <p className="text-2xl">{starsSwooshed}</p>
        </div>
        <div className="grid gap-4">
          <Card>
            <CardHeader>
              <CardTitle>Starburst</CardTitle>
            </CardHeader>
            <CardContent>
              <p>Cost: 500 stars</p>
              <Button 
                className={`mt-2 ${starsSwooshed >= 500 && !isStarburstActive ? 'bg-green-500 hover:bg-green-600' : ''}`}
                onClick={buyStarburst}
                disabled={starsSwooshed < 500 || isStarburstActive}
              >
                {isStarburstActive ? `Active (${starburstTimeLeft}s)` : 'Buy Upgrade'}
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}
